.content {
	display: flex;
	padding: 30px;
	font-weight: 500;
}

.Sidebars {
	display: block;
	min-width: 150px;
}

main {
	margin: 0 auto;
}

.add-button {
  text-decoration: none;
  padding: 20px;
  display: block;
  text-align: center;
  color: #555;
}

.add-button:hover {
  color: #505f90;
  font-weight: 700;
}

.button_wrapper {
	padding: 20px 0;
  text-align: right;
}

.action-button {
	border: 2px solid #172556;
	padding: 10px;
	font-size: 14px;
}

.action-button:hover {
	background: #2f3f73;
	color: #fff;
	transition: 250ms all;
}

input {
	line-height: 20px;
}